<template>
    <div class="warp">
        <div class="container">
            <div class="left">
                <div class="question-info">
                     <span class="yulan" @click="close">关闭预览</span>
                    <!-- 标题 -->
                    <div class="question">{{question.question}}</div>
                    <!-- 问题 -->
                    <div class="describe">{{question.describe}}</div>
                    <div class="q-btns">
                      <!-- 分类 -->
                        <div class="time"><span>问题分类：</span>{{question.teamTypes}}</div>
                        <!-- 显示图片 -->
                 <div>
                     <!-- 如果是图片 -->
                     <div class="pic_list_box" v-show="question.imgDatas!=API">
                          <img   width="350px" height="150px" :src="question.imgDatas" alt=""  >
                         <!-- <div class="pic_list">  -->
                          <!-- <div v-for="(src,index) in question.imgDatas" :key="index">  -->
                         <!-- 利用element-ui的图片预览插件  -->
                         <!-- <el-image
                         style="width: 300px; height: 150px"
                        :src="src"
                        :preview-src-list="question.imgDatas">
                        </el-image>  -->
                        <!-- <img :src="question.imgDatas"/> -->
                       <!-- </div>  -->
                   <!-- </div> -->
              </div>  
              <!-- 如果是视频v-for=" (video,index) in question.preShow" :key="index" -->
              <div class="pre-list" v-show="question.preShow!=API+undefined&&question.preShow!=API" >
                 <video
                  :src="question.preShow"
                  width="350"
                  height="180"
                  controls="controls"
                >您的浏览器不支持视频播放</video>
              </div>
             
                        </div>
                        <!-- 关键字 -->
                        <i class="keyword-icon">
                        </i>
                        <span class="word" v-for="(word) in question.keywords" :key="word">
                          <span class="key"> {{word}}</span> 
                        </span>
                    </div>
                    <div class="ask-setinfo">
                        <div>
                            <span>悬赏设置: {{rewardType}}</span>     
                            <span v-if="this.question.reward.type!=1">金额:{{question.reward.pay}}知识币</span>  
                            <span v-if="this.question.reward.type!=1" v-show="question.reward.endTime!='NaN-0NaN-0NaN 0NaN:0NaN:0NaN'">悬赏截止时间:{{question.reward.endTime}}</span></div>
                        <div>
                            <span>回答方式: {{solveType}}</span>  
                            <span v-if="this.question.solve.type==2" >
                                <span v-show="question.solve.startTime!='NaN-0NaN-0NaN 0NaN:0NaN:0NaN'">求解时间:{{question.solve.startTime}}</span>
                                <span v-show="question.solve.endTime!='NaN-0NaN-0NaN 0NaN:0NaN:0NaN'">至 {{question.solve.endTime}}</span> 
                            </span> 
                            <span v-if="this.question.solve.type==2">地点：{{question.solve.adress}}</span>
                             </div>
                        <div>
                            <span>查看分成: {{sharedType}}</span>   
                            <span v-if="this.question.shared.type!=1">金额:{{question.shared.pay}}知识币</span>
                            <span v-if="this.question.shared.type!=1">提问者的比例：{{question.shared.release}}%</span>
                            <span v-if="this.question.shared.type!=1">被采纳者的比例：{{question.shared.answer}}%</span>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Question from "@/components/question/index.vue";
import InviteAnswer from "@/views/wisdom-answer/common/invite-answer/index.vue";
import { DELETE,QUERY,UPDATE, INSERT } from '../../../services/dao';
import { dataFormat ,showTimeFormat} from "@/js/common/index.js";
import { Alert } from 'element-ui';
export default {
    data(){
        return{
             question:{
                questioner:-1,//问题回答者
                question:"",//标题
                id:-1,
                imgDatas:"",//图片
                preShow:"",//视频
                title:"",//"算法正则化与拉格朗日乘子法有什么联系？",
                describe:"",//"算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联有什么联算法正则化与拉格朗日乘子法有什么联",
                keywords:[],//["大哥1","小弟2"],
                teamType:"",//"1987-01-01:12:12:12",
                qsetting:[],
                teamTypes:"",
                file:[{
                    url:"",
                    type:"",
                }],
                // file:[{
                //     url:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1585215642154&di=539e3427e31e40697e28cb447caceda8&imgtype=0&src=http%3A%2F%2Fa3.att.hudong.com%2F14%2F75%2F01300000164186121366756803686.jpg",
                //     type:"img"
                // },{
                //     url:"",
                //     type:"video"
                // }],
                shared:{
                    type:1,    //分成 1-免费 2-分成
                    pay:1,
                    release:0,
                    answer:0
                },
                reward:{
                    type:1,  //悬赏类型 1-免费 2-竞价成交 3-抢答成交 4-信用度成交 5选择成交
                    pay:1,
                    endTime:''//'2020-04-20 12:00:00'
                },
                solve:{
                    type:1,
                    startTime:'',//'2020-04-20 12:00:00',
                    endTime:'',//'2020-04-20 12:00:00',
                    adress:''
                }
            },
            API:""
        }
    },
    created(){
        this.API=this.api.LoginURL.concat();
        //显示标题
        this.question.question=this.$route.query.question;
        //内容
        this.question.describe=this.$route.query.describe;
        //标签
        this.question.keywords=this.$route.query.keywords;
        //分类
        this.question.teamType=this.$route.query.teamType;
        //图片
        this.question.imgDatas=this.api.LoginURL.concat(this.$route.query.dialogImageUrl);
        //视频
        this.question.preShow=this.api.LoginURL.concat(this.$route.query.dialogVideo);
        //悬赏、解答方式、分成
        this.question.reward.pay=this.$route.query.qsetting.reward.pay;
        this.question.reward.endTime=showTimeFormat(this.$route.query.qsetting.reward.endTime);
        this.question.solve.startTime=showTimeFormat(this.$route.query.qsetting.solve.startTime);
        this.question.solve.endTime=showTimeFormat(this.$route.query.qsetting.solve.endTime);
        this.question.shared.type=this.$route.query.qsetting.shared.type;
        this.question.shared.pay=this.$route.query.qsetting.shared.pay;
        this.question.shared.release=this.$route.query.qsetting.shared.release;//分成比例
        this.question.shared.answer=this.$route.query.qsetting.shared.answer;//分成比例
        this.question.reward.type=this.$route.query.qsetting.reward.type;
        this.question.solve.type=this.$route.query.qsetting.solve.type;
        this.question.solve.adress=this.$route.query.qsetting.solve.adress;
        this.qsetting=this.$route.query.qsetting;
        this.question.teamTypes=this.$route.query.teamTypes;
        //alert(this.question.reward.type)
                //  question:this.question,//标题
                //  describe:this.describe,//内容
                //  imgDatas:this.imgDatas,//图片
                //  preShow:this.preShow,//视频
                //  teamType:this.teamType,//分类
                //  keywords:this.keywords,//标签
                //  qsetting:this.qsetting//悬赏、解答方式、分成
    },   
    computed:{       
       rewardType :function(){
            if(this.question.reward.type==1){
                return '免费';
            }
            if(this.question.reward.type==2){
                return '竞价成交';
            }
            if(this.question.reward.type==3){
                return '抢答成交';
            }
                        
            if(this.question.reward.type==4){
                return '信用度成交';
            }
            if(this.question.reward.type==5){
                return '选择成交';
            }
            return '';
        },
        solveType:function(){
            if(this.question.solve.type==1){
                return "线上";
            }
            if(this.question.solve.type==2){
                return "线下";
            }
            return "";
        },
        sharedType:function(){
            if(this.question.shared.type==1){
                return "免费";
            }
            if(this.question.shared.type==2){
                return "分成";
            }
            return "";
        }
    },
    methods:{
        close(){
            // this.$router.push({path:"/askQuestion"});
            this.$router.push({ name: "AskQuestion", params: { 
        //显示标题
            question:this.question.question,
            //内容
            describe:this.question.describe,
            // 图片
           imgDatas:this.$route.query.dialogImageUrl,
            //视频
           preShow:this.$route.query.dialogVideo,
            //标签
            keywords:this.question.keywords,
            // 问题分类
            teamTypes:this.question.teamTypes,
            //分类
            teamType:this.question.teamType,
            //悬赏、解答方式、分成
            qsetting: this.qsetting
            } });
        }
    }
}
</script>

<style scoped>
    .container{
        width:750px;
        margin: 0 auto;
    }
    .describe{
        margin-top: 10px;
    }
    .pic_list{
        margin-top: 10px;
        /* border: 1px solid red; */
    }
    .yulan{
        width: 100%;
        height: 20px;
        text-align: right;
        display: inline-block;
        font-size: 12px;
        cursor: pointer;
    }
    .question{
        font-size: 16px;
        /* height: 20px; */
        /* line-height: 20px; */
        font-weight: bold;
        width: 100%;
        margin-bottom: 5px;
        /* border: 1px solid red; */
    }
    .describe{
        width: 100%;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .key{
        position: relative;
        padding: 3px;
        border: 1px solid #8590A6;
        border-radius:12px;
    }
    .container .left{
        width: 750px;
    }
    .container .right{
        width:330px;
        margin-left:15px
    }
    .question-info,.app-noanswer,.q-similarity{
        background-color: #fff;
           
    }
    .question-info{
        padding: 20px 30px 0 30px;
    }
    .app-noanswer{
        padding:10px 30px;
    }
    .q-similarity{
          padding:10px 30px;
    }
    .keyword-block{
        padding: 20px 0;
    }
    .q-btns {
        position: relative;
        margin-bottom: 10px;
    }
    .q-btns *{
        font-size:12px;
        font-weight:300;
        color:rgba(118,118,118,1);
        vertical-align: middle;
        margin-bottom: 5px;
    } 
   .q-btns .word{
       margin-left: 5px;
   }

   .question-info .right-btns{

       text-align: right;
   }
    .question-info .right-btns>span{
        margin-left: 20px;
    }
    .question-info .ask-setinfo{
        margin-top: 20px;
        min-height: 80px;
        background-color: #F0F1F1;
        padding: 10px;
    }
    .ask-setinfo>div{
        height: 25px;
        line-height: 25px;
        font-size: 11px;
    }
    .ask-setinfo>div>span{
        margin-right: 10px;
    }
   .q-btns .right-btns>span{
       margin-left:10px;
     
   }
   .q-btns .right-btns>span>*{
         vertical-align: middle;
         margin-left: 5px;
   }
    .share-icon{
         display: inline-block;
        width:14px;
        height:12px;
        background-image: url("~@/assets/icon/share.png");
    }
    .invite-icon{
         display: inline-block;
        width:18px;
        height:16px;
        /* background-image: url("~@/assets/icon/invite-icon.png"); */
          background-image: url("~@/assets/icon/invite.png");
    }
   .left .q-note-inform{
       margin-top:20px ;
       font-size:14px;
        font-weight:300;
        color:rgba(118,118,118,1);
        padding-bottom: 22px;
   }
  .q-note-inform .showtellphone{
      float:right;
  }
   .q-note-inform .modify{
       margin-left: 20px;
   }
   .q-app{
       text-align: right;
        font-size:14px;
        font-weight:300;
        margin: 20px 0;
   }
   .q-app>*{
       vertical-align: middle;

   }
   .q-app span{
       color: #0084FF;
   }
   .tellphone-icon{
       display: inline-block;
       width: 12px;
       height: 16px;
       background-image: url(~@/assets/icon/tellphone-icon.png);
   }
   .q-nofit-answer{
       padding: 20px 10px;
       background-color:rgba(248,249,250,1);
       margin-bottom: 10px;
   }
   .answer-icon{
       display: inline-block;
       width: 16px;
       height: 16px;
       background-image: url(~@/assets/icon/answer-icon.png);
   }
   .q-nofit-answer>span{
       margin-left: 10px;
       font-size:14px;
   }
   .q-similarity{
       padding-top:30px;
       padding-bottom:80px
   }
    .smil-label{
        padding:20px 0 ;
    }
   .smil-label>*{
       vertical-align: middle;
   }
   .smil-label span{
       padding-left: 10px;
       font-size:16px;
       font-weight:400;
       color:rgba(112,112,112,1);
   }
   .similar-icon{
       display: inline-block;
       width: 18px;
       height: 16px;
       background-image: url(~@/assets/icon/similar-icon.png);
   }
   .q-similarity .smil-qustion{
        padding-top: 10px;
   }
   .app-noanswer{
       background-color:#fff ;
   }
   .sq-one>div{
       padding: 5px 0;
   }
   .sq-one .sq-answer>:first-child{
       color: #0084FF;
   }
   .more-smil-question{
       color:#767676;
       font-size:14px;
       cursor: pointer;
   }
   .more-smil-question:hover,.show-hand:hover{
       color:red;
   }
   .show-hand{
        cursor: pointer;
   }
    .sq-one{
        cursor: pointer;
    }
   .sq-one:hover{
       box-shadow: 2px 2px 5px #bbb;
       background-color:#eeebeb ;
       border: 1px solid #f5f0f0;
       padding: 10px;
   }
</style>